
.comment_list_wrapper {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    margin-bottom: 26px;
}

.avatarImg {
    text-align: center;
    background: #ff9200;
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.userNameWrapper {
    font-size: 14px;
    font-weight: 560;
    margin-right: 10px;
    color: #9500ff;
}

.commentBody {
    font-size: 14px;
}

.commentActionButtons {
    margin-top: 3px;
    display: flex;
    gap: 10px;
}

.dateCreated {
    font-size: 13px;
    color: #827f7f;
}

.replyButton{
    font-size: 11px;
    color: #007eff;
    font-weight: 600;
    cursor: pointer;
}


.send_comment {
    margin-top: 8px;
    border-radius: 5px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    font-size: 14px;
    padding: 5px 15px;
    cursor: pointer;
}

.postYourComment {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.send_comment:hover {
    background: grey;
    color: #fff;
}

.repliesWrapper {
    margin: 8px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
}

.post-your-comment.replying {
    position: relative;
}

.repliedOnSubmitWrapper {
    background: #ddd;
    width: 100%;
}

.replying_container {
    width: 100%;
    background: #fff;
    padding: 4px;
    border-radius: 5px;
    color: #000;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.repliedOnSubmitWrapper {
    padding: 6px 5px;
    display: flex;
    justify-content: space-between;
}


.wFull {
    width: 100%;
}